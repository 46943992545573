import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { ADMIN_AEPS_LOGIN_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { aepsLoginInArray } from './../../../Utils/Common';
import { useHistory } from "react-router-dom";

import { DatePicker, Tooltip, Select } from 'antd';
import { toDayDate, queryDate, getExportFormat, getUTCToLocalDateFormat } from './../../../Utils/function';

function AepsLoginReport() {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';

    const [isLoading, setLoading] = useState(false);
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchServiceName, setSearchServiceName] = useState(null);
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateFormat(row.date),
        width: "120px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Ref ID / Name',
        selector: row => row.users && row.users.username + " / " + row.users.name,
        width: "250px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Pancard',
        selector: row => row.users && row.users.pancard,
        width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Service Name',
        selector: row => <Tooltip placement="bottom" title={row.apiName}>{row.apiName}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Message',
        selector: row => row.apiresponses.shortResponse.message ? <Tooltip placement="bottom" title={row.apiresponses.shortResponse.message}>{row.apiresponses.shortResponse.message}</Tooltip> : <Tooltip placement="bottom" title={row.apiresponses.shortResponse.status}>{row.apiresponses.shortResponse.status}</Tooltip>,
        width: "250px",
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        setLoading(true)
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_AEPS_LOGIN_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchServiceName = searchServiceName ? searchServiceName : "";

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_AEPS_LOGIN_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date": getExportFormat(e.date),
                        "TXT Id": e.transactionId,
                        "Ref ID": e.users.username,
                        "Pancard": e.users.pancard,
                        "Name": e.users.name,
                        "Service Name": e.apiName,
                        "Message": e.apiresponses.shortResponse.message ? e.apiresponses.shortResponse.message : e.apiresponses.shortResponse.status,
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setSearchUserId('');
        setSearchServiceName('');
    }

    useEffect(() => {
        if(startDate || page>0) {
            getDataReports();
        }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>AePS Login <span className='text-red'> Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-10 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-md-3 col-lg-3 d-flex'>
                                                <div className="form-group col-12">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} value={startDate} onChange={onChangeStartDate} />
                                                </div>
                                            </div>
                                            <div className='col-md-3 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                </div>
                                            </div>
                                            <div className='col-md-3 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <Select options={aepsLoginInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Service Name --" name='mode' onChange={(e) => setSearchServiceName(e)} />
                                                    {/* <input type="text" className="form-control form-control-border" placeholder="Service Name" onChange={(e) => setSearchServiceName(e.target.value)} value={searchServiceName} /> */}
                                                </div>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="button" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename={queryDate(startDate)+'-daily-transaction'}
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        progressPending={isLoading}
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AepsLoginReport;