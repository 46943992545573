import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_DMT_TRANS_REPORT, POST_DMT_FAIL_TRANSACTION, POST_DMT_REINITIATE_TRANSACTION, GET_DMT_TRANSACTION_INQUIRY, POST_DMT_INSTANT_PAY_RE_INITIATE_TRANSACTION, /* POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY, POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY */ } from './../../../Utils/appConstants';
import { dmtStatus, dmtStatusExport, dmtDetailStatus, dmtStatusInArray, dmtModeInArray, channelStatus } from './../../../Utils/Common';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
// import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip, Table, Space, Tag, Select } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate, getExportFormat } from '../../../Utils/function';

function DmtHistoryReport(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [searchMode, setSearchMode] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    const [isChecked, setChecked] = useState([]);

    const onClick = (event) => {
        let { checked, value } = event.target;
        // console.log("checked-----------------------", checked)
        if (checked) {
            setChecked([...isChecked, value]);
        } else {
            const updatedArray = isChecked.filter(item => item !== value);
            setChecked(updatedArray);
        }
    }

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.client_ref_id && row.client_ref_id}>{row.client_ref_id && row.client_ref_id}</Tooltip>,
        width: "180px",
    }, {
        name: 'Amount',
        selector: row => row.amount + ' ' + row.currency,
        width: "100px",
    }, {
        name: 'Status',
        selector: row => dmtStatus(row.txtStatus),
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.channel),
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.users && row.users.username}>{row.users && row.users.username}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank Name',
        selector: row => (row.baneData && row.baneData.bankName) || (row.baneData1 && row.baneData1.bankName),
        width: "220px",
    }, {
        name: 'IFSC Code',
        selector: row => row.ifsccode,
        width: "120px",
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => row.baneData ? row.baneData.beneName : (row.baneData1) ? row.baneData1.beneName : "",
        width: "190px",
    }, {
        name: 'Action',
        selector: row => row.isInsufficientFund && row.txtStatus === 0 && <button type="button" className='btn btn-sm border border-dark text-dark' onClick={() => handleSubmit(row)} title='Check Status'><i className="fas fa-sync"></i>Check Status</button>,
        width: "180px",
    }, {
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.message && row.message}>{row.message && row.message}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }]

    const handleSubmit = (data) => {
        setLoading(true)
        const payload = { transactionId: data.client_ref_id }
        // console.log("data ----", data)
        let URL = "";
        if (data.client_ref_id.includes("SI") || data.apiName === "Instant Pay") {
            URL = POST_DMT_INSTANT_PAY_RE_INITIATE_TRANSACTION;
        } else {
            URL = POST_DMT_REINITIATE_TRANSACTION;
        }
        // console.log("URL ----", URL)
        postRequest(`${URL}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const failTransaction = () => {
        // console.log("Checked ------------", isChecked)
        if (isChecked.length > 0) {
            postRequest(`${POST_DMT_FAIL_TRANSACTION}`, isChecked, history).then((response) => {
                if (response.success === true) {
                    getDataReports();
                    toast.success(response.message);
                }
            }).catch(function (error) {
                console.log(error)
                // setLoading(false);
            })
        } else {
            toast.error("Minimum one record is selected")
        }
    }
    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        setLoading(true)
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.searchMode = searchMode ? searchMode : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANS_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false)
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.searchMode = searchMode ? searchMode : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANS_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getExportFormat(e.createdAt),
                        "Trans ID": e.client_ref_id,
                        "Amount": e.amount,
                        "Status": dmtStatusExport(e.txtStatus),
                        "Mode": channelStatus(e.channel),
                        "Customer Id": e.customer_id,
                        "Partner Id": e.users && e.users.username,
                        "Bank Name": (e.baneData && e.baneData.bankName) || (e.baneData1 && e.baneData1.bankName),
                        "IFSC Code": e.ifsccode,
                        "Account Number": e.accountNumber,
                        "Bene Name": e.baneData ? e.baneData.beneName : (e.baneData1) ? e.baneData1.beneName : "",
                        "Message": e.message,
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log("date----------", dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const transactionInquiry = (data) => {
        // console.log("data -------------", data.parentTransactionId)
        setLoading(true)
        const payload = { transactionId: data.transactionId }
        let URL = GET_DMT_TRANSACTION_INQUIRY;
        // if (data.transactionId.includes("SI") || data.apiName === "Instant Pay") {
        //     URL = POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY;
        // } else if (data.transactionId.includes("SP")) {
        //     URL = POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY;
        // } else {
        //     URL = GET_DMT_TRANSACTION_INQUIRY;
        // }
        postRequest(`${URL}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const onChangeType = (value) => {
        // const { value } = event;
        // console.log(`selected ${value}`);
        setSearchStatus(value);
    }

    const onChangeTypeMode = (value) => {
        // const { value } = event;
        // console.log(`selected ${value}`);
        setSearchMode(value);
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
        setSearchUserId('');
    }

    // useEffect(() => {
    //     if (searchAccountNumber || searchTransId || searchCustomerId || searchUserId || searchStatus || searchMode) {
    //         handleSearch();
    //         // console.log("--------12---------")
    //     }
    // }, [searchAccountNumber, searchTransId, searchCustomerId, searchUserId, searchStatus, searchMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status
    const ExpandableComponent = (props, key) => <div className='ml-5 w-50'><Table dataSource={props.data.dmttransactiondetails} key={key} columns={[
        {
            title: "#",
            render: (record, key) => <><input type="checkbox" className="pointer" onChange={(e) => onClick(e)} checked={isChecked.includes(record._id)} disabled={record.isInsufficientFund && record.tx_status === "0" ? false : true} value={record._id} /></>,
        },
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        }, {
            title: 'Bank RRN',
            dataIndex: 'bank_ref_num',
            key: 'bank_ref_num',
        }, {
            title: 'Status',
            // dataIndex: 'txstatus_desc',
            key: 'txstatus_desc',
            render: (record, key) => (
                <><Space key={key} size="middle">{dmtDetailStatus(record.tx_status)}</Space></>
            )
        }, {
            title: "Action",
            key: "action",
            render: (record, key) => (
                <><Space key={key} size="middle">
                    {!record.isRefund && (record.tx_status === "0" || record.tx_status === "1" || record.tx_status === "2" || record.tx_status === "3" || record.tx_status === "5") && <Tag color={"yellow"} onClick={() => transactionInquiry(record)} className='pointer' key={0}>
                        INQUIRY
                    </Tag>}
                </Space></>
            )
        }, {
            title: 'Message',
            dataIndex: 'reason',
            key: 'reason',
        }
    ]} />
    </div>
    

    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Money Transfer <span className='text-red'>Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>

                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} value={searchAccountNumber} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} value={searchCustomerId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtModeInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Mode --" name='mode' onChange={(e) => onChangeTypeMode(e)} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtStatusInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CSVLink
                                        data={getCsvData}
                                        filename='money-transfer-report'
                                        className='hidden'
                                        ref={csvLink}
                                        target='_blank'
                                    />
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            expandableRows
                                            actions={<><button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button><button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button><button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button><button disabled={isChecked.length > 0 ? false : true} onClick={() => failTransaction()} className='btn btn-danger btn-sm'>Fail Transaction</button></>}
                                            // expandableRowExpanded={row => row.index}
                                            progressPending={isLoading}
                                            expandableRowsComponent={ExpandableComponent}
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DmtHistoryReport