import React, { useEffect, useState, useRef, useCallback } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { Modal, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_GET_ALL_KYC_PENDING_USERS, ADMIN_ACCEPT_PROFILE, UPLOAD_IMAGE_FILE, AADHARCARD_VERIFY, AADHARCARD_OTP_VERIFY } from '../../../Utils/appConstants';
import { getRequest, postRequest } from '../../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { kycStatusValue, getUser, FundRequestValueCSV, INRFormat } from '../../../Utils/Common';
import ImageViewer from 'react-simple-image-viewer';
import ImageCropper from "../../../Utils/imageCropper";
import moment from 'moment';
import { getUTCToLocalDateFormat } from '../../../Utils/function';
import RequestMoney from '../../requestMoney';
// import Moment from 'react-moment';
// import { invalid } from 'moment';

function App(props) {
    // console.log("props -------",props.search)
    const user = getUser();
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";
    const kycStatus = props.kycStatus ? props.kycStatus : "";

    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isModalUserDetail, setIsModalUserDetail] = useState(false);
    // const [isModalUserApprovedReject, setIsModalUserApprovedReject] = useState(false);
    const [isSelectedUser, setIsSelectedUser] = useState([]);
    const [isSelectedUserAadharDetail, setIsSelectedUserAadharDetail] = useState({});
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [isReason, setIsReason] = useState("");
    const [isReasonVisble, setIsReasonVisble] = useState(false);

    const [imageCropperModal, setImageCropperModal] = useState(false);
    const [imageNameModal, setImageNameModal] = useState(null);
    const [updateFieldName, setUpdateFieldName] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [isAadharCardVerify, setIsAadharCardVerify] = useState(false);
    const [aadharNumber, setAadharNumber] = useState(null);
    const [isOtp, setIsOtp] = useState(false);
    const [isOtpNumber, setIsOtpNumber] = useState(null);
    const [isRefId, setIsRefId] = useState(null);

    const [isModalFundTopup, setIsModalFundTopup] = useState(false);
    const [requestMoneyUserId, setRequestMoneyUserId] = useState("");
    const [requestMoneyTitle, setRequestMoneyTitle] = useState("");

    const columns = [{
        name: 'User Id',
        selector: row => row.user.username,
    }, {
        name: 'Pan No.',
        selector: row => row.pancardNumber,
    }, {
        name: 'Name',
        selector: row => row.user.name,
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
    }, {
        name: 'User Type',
        selector: row => row.role.name,
    }, {
        name: 'Wallet',
        selector: row => row.walletbalances.length > 0 ? <Tooltip placement="bottom" title={INRFormat(row.walletbalances[0].balance)}>{INRFormat(row.walletbalances[0].balance)}</Tooltip> : 0,
    }, {
        name: 'Capital',
        selector: row => row.user.capitalAmount ? <Tooltip placement="bottom" title={INRFormat(row.user.capitalAmount)}>{INRFormat(row.user.capitalAmount)}</Tooltip> : 0,
    },{
        name: 'Khata',
        selector: row => (row.khatabooks.length > 0) ? <Tooltip placement="bottom" title={INRFormat(row.khatabooks[0].closingBalance)}>{INRFormat(row.khatabooks[0].closingBalance)}</Tooltip> : 0,
    }, {
        name: 'Parent ID',
        selector: row => <>{row.userParentDetail && row.userParentDetail.username}</>,
    }, {
        name: 'KYC Status',
        cell: row => <>{kycStatusValue(row.user.KYCStatus)}</>,
    }, {
        name: 'Active',
        cell: row => <>{(row.user.active) ? <div className="text-success">Active</div> : <div className="text-danger">Deactive</div>}</>,
    }, {
        name: 'Date',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' onClick={(event) => handleUserDetail(event, row)}><i className="fa fa-eye" aria-hidden="true"></i></div>{user.data && user.data.role.code !== 'A' && <div className='icon-border border border-primary text-primary pointer' title='Fund Transfer' onClick={(event) => handleFundTopup(event, row)}><i className="fa fa-share" aria-hidden="true"></i></div>}</>,
    }]

    // {!row.aadharNumberVerify && <div className='icon-border border border-danger text-danger pointer' onClick={(event) => handleAadhaar(event, row)}><i class="fa fa-info-circle" aria-hidden="true"></i></div>}

    const handleFundTopup = (event, data) => {
        setIsModalFundTopup(true)
        setRequestMoneyTitle(data.user)
        setRequestMoneyUserId(data.userId)
    }

    const handleAadharCardOtpVerify = () => {
        if (isOtp) {
          const payload = { userKycId: isSelectedUser.userId, aadharcard: aadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId };
          postRequest(`${AADHARCARD_VERIFY}`, payload, history).then((response) => {
            if (response.success === true || response.alreadysuccess === true) {
              setIsOtp(false)
              setIsAadharCardVerify(false)
            //   user.data.userDetails.aadharNumberVerify = true;
            //   updateUserSession(user)
              toast.success(response.message);
            } else {
              if (!response.aadharNumber || !response.alreadysuccess) {
                // setAadharcardVerifyFailedMessage(response.message);
                // setAadharcardVerifyFailed(true);
                toast.error(response.message);
              } else {
                setIsOtp(false);
                toast.error(response.message);
              }
            }
          }).catch(function (error) {
            setIsAadharCardVerify(false)
          })
        } else {
          const payload = { userKycId: isSelectedUser.userId, aadharcard: aadharNumber };
          postRequest(`${AADHARCARD_OTP_VERIFY}`, payload, history).then((response) => {
            if (response.alreadysuccess === true) {
              setIsOtp(false)
              setIsAadharCardVerify(false)
              user.data.userDetails.aadharNumberVerify = true;
            //   updateUserSession(user)
              toast.success(response.message);
            } else if (response.success === true) {
              setIsOtp(true);
              setIsRefId(response.data.ref_id);
              toast.success(response.message);
            } else {
              toast.error(response.message);
            }
          }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
          })
        }
      }

    const handleUserDetail = (event, data) => {
        setIsModalUserDetail(true)
        setIsSelectedUser(data)
        let user = JSON.parse(JSON.stringify(data.aadharNumberVerifiedDetail));
        setIsSelectedUserAadharDetail(user)
        // console.log("data ---------------", user.name)
    }

    const handleReject = (data) => {
        const payload = {
            userId: data.userId,
            status: false,
            reason: isReason
        }
        // console.log("isReason --------------", isReason)
        if (!isReason) {
            setIsReasonVisble(true)
            setIsReason(data.rejectReason)
        } else {
            handleSubmit(payload);
        }
    }

    const handleApproved = (data) => {
        if (user.data && user.data.role.order === 2) {
            const payload = {
                userId: data.userId,
                status: true
            }

            handleSubmit(payload);
        } else {
            toast.error("You have not permission for a approved user")
        }
    }

    const handleSubmit = (data) => {
        // const queryString = objectToQueryString(payload);
        postRequest(`${ADMIN_ACCEPT_PROFILE}`, data, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setIsReason("")
                setIsReasonVisble(false)
                setIsModalUserDetail(false)
                getDataReports();
            } else {
                toast.error(response.message);
                if (!data.status) {
                    setIsReasonVisble(true)
                }
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
        // console.log("accept ----",value)
    }
    const handleHideModal = () => {
        setIsModalUserDetail(false);
        // setIsModalUserApprovedReject(false)
        setIsReason("")
        setIsReasonVisble(false)
        setImageCropperModal(false)
        setIsModalFundTopup(false)
        setIsModalUserDetail(false);
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        if (kycStatus) {
            params.kycStatus = kycStatus;
        }
        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_KYC_PENDING_USERS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const getCSVDataList = () => {
      let params = {}
      params.exportsCsv = true;
      if (startDate && endDate) {
        params.startDate = startDate ? startDate : "";
        params.endDate = endDate ? endDate : "";
        }
        if (kycStatus) {
            params.kycStatus = kycStatus;
        }
        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }

      const queryString = objectToQueryString(params);
      getRequest(`${ADMIN_GET_ALL_KYC_PENDING_USERS}?${queryString}`, history).then((response) => {
        if (response.success === true) {
          let csvdataRecord = [];
          let csvdata = response.data;
          csvdata.map((e) => {
            return csvdataRecord.push({
                "Date": moment(e.createdAt).format("DD/MM/YYYY"),
                "User Id": e.user.username,
                "Name": e.user.name,
                "Mobile": e.mobile,
                "User Type": e.role.name,
                "Parent ID": e.userParentDetail && e.userParentDetail.username,
                "Wallet": (e.walletbalances.length > 0) ? e.walletbalances[0].balance : 0,
                "Capital": e.user.capitalAmount,
                "Khata": (e.khatabooks.length > 0) ? e.khatabooks[0].closingBalance : 0,
                "Parent Name": e.userParentDetail && e.userParentDetail.name,
                "Outlet Name": e.outletName,
                "KYC Status": FundRequestValueCSV(e.user.KYCStatus),
                "Reason": e.rejectReason});
          });
          setCsvData(csvdataRecord);
          csvLink.current.link.click()
        } else {
          setCsvData([]);
        }
      }).catch(function (error) {
        console.log(error)
        // setLoading(false);
      })
    } 

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(imageCropperModal) {
            getDataReports();
        }
    }, [kycStatus, isSelectedUser]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     getDataReports();
    // }, [g]) // eslint-disable-line react-hooks/exhaustive-deps

    const openImageViewer = useCallback((image) => {
        setViewImage(image)
        setIsModalUserDetail(false);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setIsModalUserDetail(true);
        setIsViewerOpen(false);
    };

    const handleFileChange = (event, name, field) => {
        setImageCropperModal(true);
        setImageNameModal(name);
        setImageFile(event);
        setUpdateFieldName(field)
    }

    const handleFileUpload = async (uploadFile) => {
        if (uploadFile) {
            let blob = await fetch(uploadFile).then(res => res.blob()).then(blob => new File([blob], 'image', { type: "image/png" }));
            const data = new FormData();
            data.append('avatar', blob);
            data.append('name', imageNameModal);
            data.append('username', isSelectedUser.user.username);
            await postRequest(UPLOAD_IMAGE_FILE, data, history).then((response) => {
                if (response.success === true) {
                    console.log("response.data --------", response.data.fullpath)
                    setData({ ...getData, [updateFieldName]: response.data.fullpath });
                    setIsSelectedUser({ ...isSelectedUser, [updateFieldName]: response.data.fullpath });
                    setImageCropperModal(false);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                console.log("error-------", error)
            })
        }
    }

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='kyc_pending.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        {/* <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button> */}
                    </div>
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {isModalUserDetail && <Modal title={isSelectedUser.user.name} width={600} open={isModalUserDetail} onCancel={() => handleReject(isSelectedUser)} okText={"Approve"} onOk={() => handleApproved(isSelectedUser)} cancelText={"Reject"}>
                {isSelectedUser ?
                    <ul>
                        <li><span>Name</span> : {isSelectedUser.user.name}</li>
                        <li><span>Role Name</span> : {isSelectedUser.role.name}</li>
                        <li><span>Father Name</span> : {isSelectedUser.fatherName}</li>
                        <li><span>Mother Name</span> : {isSelectedUser.motherName}</li>
                        <li><span>Mobile Number</span> : {isSelectedUser.mobile}</li>
                        <li><span>Email</span> : {isSelectedUser.email}</li>
                        <li><span>Email Verify</span> : {isSelectedUser.emailVerify}</li>
                        <li><span>Phone Number</span> : {isSelectedUser.phone}</li>
                        <li><span>Pincode</span> : {isSelectedUser.pincode}</li>
                        <li><span>City Name</span> : {isSelectedUser.city}</li>
                        <li><span>State Name</span> : {isSelectedUser.stateObjectId && isSelectedUser.state[0].name}</li>
                        <li><span>Outlet Name</span> : {isSelectedUser.outletName}</li>
                        <li><span>Account Number</span> : {isSelectedUser.accountNumber}</li>
                        <li><span>IFSC Code</span> : {isSelectedUser.ifscCode}</li>
                        <li><span>Bank Name</span> : {isSelectedUser.bankName}</li>
                        <li><span>Account Holder Name</span> : {isSelectedUser.accountHolderName}</li>
                        <li><span>Bank Proof</span> : <div className='text-right'>
                            <img src={isSelectedUser.bankProof} width={'1000px'} alt="" />
                            <button className='btn btn-xs bg-primary pointer m-1' onClick={() => openImageViewer(isSelectedUser.bankProof)}>Show Image</button>
                            <button className='btn btn-xs bg-info pointer m-1' onClick={() => handleFileChange(isSelectedUser.bankProof, "bankProofUpload", "bankProof")}>Crop Image</button>
                            <hr className='m-0' />
                        </div>
                        </li>
                        <li><span>Pancard Number</span> : {isSelectedUser.pancardNumber}</li>
                        <li><span>Pancard Number Verify</span> : {isSelectedUser.pancardNumberVerify ? "Yes" : "No"}</li>
                        {isSelectedUser.pancardNumberVerifiedDetail && (
                            <><li><span>Pancard Type</span> : {isSelectedUser.pancardNumberVerifiedDetail.type}</li>
                                <li><span>Pancard Name</span> : {isSelectedUser.pancardNumberVerifiedDetail.registered_name}</li>
                                <li><span>Pancard Status</span> : {(isSelectedUser.pancardNumberVerifiedDetail.valid) ? "Valid" : "Invalid"}</li></>)}
                        <li><span>Pan Card Photo</span> : <div className='text-right'>
                            <img src={isSelectedUser.panCardPhoto} width={'1000px'} alt="" />
                            <button className='btn btn-xs bg-primary pointer m-1' onClick={() => openImageViewer(isSelectedUser.panCardPhoto)}>Show Image</button>
                            <button className='btn btn-xs bg-info pointer m-1' onClick={() => handleFileChange(isSelectedUser.panCardPhoto, "panCardPhotoUpload", "panCardPhoto")}>Crop Image</button>
                            <hr className='m-0' />
                        </div>
                        </li>
                        <li><span>Aadhar Number</span> : {isSelectedUser.aadharNumber}</li>
                        <li><span>Aadhar Number Verify</span> : {isSelectedUser.aadharNumberVerify ? "Yes" : "No"}</li>
                        <li><span>Aadhar Name</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.name }</li>
                        <li><span>Address</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.address }</li>
                        <li><span>Date Of Birth</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.dob }</li>
                        <li><span>Aadhar Profile Photo</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.photo_link && <div className='text-right'><img className="elevation-2" src={"data:image/png;base64,"+isSelectedUserAadharDetail.photo_link} alt="User Avatar" /></div>}</li>

                        <li><span>Aadhar Card Photo</span> : <div className='text-right'>
                            <img src={isSelectedUser.aadharPhoto} width={'1000px'} alt="" />
                            <button className='btn btn-xs bg-primary pointer m-1' onClick={() => openImageViewer(isSelectedUser.aadharPhoto)}>Show Image</button>
                            <button className='btn btn-xs bg-info pointer m-1' onClick={() => handleFileChange(isSelectedUser.aadharPhoto, "aadharPhotoUpload", "aadharPhoto")}>Crop Image</button>
                            <hr className='m-0' />
                        </div>
                        </li>
                        <li><span>GST In Number</span> : {isSelectedUser.gstInNumber}</li>
                        <li><span>GST In Number Verify</span> : {isSelectedUser.gstInNumberVerify ? "Yes" : "No"}</li>
                        <li><span>GST In Photo</span> : <div className='text-right'>
                            <img src={isSelectedUser.gstInPhoto} width={'1000px'} alt="" />
                            <button className='btn btn-xs bg-primary pointer m-1' onClick={() => openImageViewer(isSelectedUser.gstInPhoto)}>Show Image</button>
                            <button className='btn btn-xs bg-info pointer m-1' onClick={() => handleFileChange(isSelectedUser.gstInPhoto, "gstInPhoto", "gstInPhoto")}>Crop Image</button>
                            <hr className='m-0' />
                        </div>
                        </li>
                        <li><span>Selfie Photo</span> : <div className='text-right'>
                            <img src={isSelectedUser.selfiePhoto} width={'1000px'} alt="" />
                            <button className='btn btn-xs bg-primary pointer m-1' onClick={() => openImageViewer(isSelectedUser.selfiePhoto)}>Show Image</button>
                            <button className='btn btn-xs bg-info pointer m-1' onClick={() => handleFileChange(isSelectedUser.selfiePhoto, "selfiePhotoUpload", "selfiePhoto")}>Crop Image</button>
                            <hr className='m-0' />
                        </div>
                        </li>
                        <li><span>Communication Address</span> : {isSelectedUser.communicationAddress}</li>
                        <li><span>Registered Address</span> : {isSelectedUser.registeredAddress}</li>
                        <li><span>Business Address</span> : {isSelectedUser.businessAddress}</li>
                    </ul>
                    : ""}
            </Modal>}

            {/* {isModalUserApprovedReject && <Modal title={"User Status"} width={600} open={isModalUserApprovedReject} onOk={() => (isSelectedUser)} onCancel={handleHideModal} okText={"Send"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 d-flex'>
                        <div className="form-group col-md-4">
                            <label>Suspend User: </label> <Switch size="small" defaultChecked={isSuspendUser} onChange={onChangeSuspendUser} />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Credit Status: </label> <Switch size="small" defaultChecked={isCreditUser} onChange={onChangeCreditStatus} />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Debit Status: </label> <Switch size="small" defaultChecked={isDebitUser} onChange={onChangeDebitStatus}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label> 
                        <textarea className="form-group form-control" cols={"4"} rows={"5"} >{isReason}</textarea>
                    </div>
                </div>
            </Modal>} */}

            {imageCropperModal && (<Modal width={800} open={imageCropperModal} onCancel={handleHideModal} footer={false}>
                <div className="font-weight-bold text-center text-danger">
                    <ImageCropper image={imageFile} handleFileUpload={handleFileUpload} />
                </div>
            </Modal>)}

            {isReasonVisble && <Modal title={"User reject reason"} width={600} open={isReasonVisble} onOk={() => handleReject(isSelectedUser)} onCancel={handleHideModal} okText={"Send"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label>
                        <textarea className="form-group form-control" onChange={(event) => setIsReason(event.target.value)} cols={"4"} rows={"5"} >{isReason ? isReason : isSelectedUser.rejectReason}</textarea>
                    </div>
                </div>
            </Modal>}
            {isAadharCardVerify && (<Modal
          title="Aadhar Card Verification"
          centered
          open={isAadharCardVerify}
          onCancel={() => setIsAadharCardVerify(false)}
          onOk={() => handleAadharCardOtpVerify()}
        >
          <div className="input-group">
            <div className="custom-file">
              <input type="text" className="form-control" name="aadharNumber" placeholder="Aadharcard Number" defaultValue={isSelectedUser && isSelectedUser.aadharNumber} maxLength={12} onChange={(e) => setAadharNumber(e.target.value)} />
            </div>
          </div>
          <hr />
          {isOtp &&
            <div className="input-group">
              <div className="custom-file">
                <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={6} onChange={(e) => setIsOtpNumber(e.target.value)} />
              </div>
            </div>
          }
        </Modal>)}

        {isModalFundTopup && <Modal title={"Fund Topup"} width={1200} open={isModalFundTopup} onCancel={handleHideModal} footer={false}>
                <RequestMoney userData={requestMoneyTitle} requestMoneyUserId={requestMoneyUserId} isRequestMoney={true} />
            </Modal>}
        </div>
    )
}

export default App