import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ELETRICITY_BILL_PAYMENT_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { UitilityOfflineStatus } from './../../../Utils/Common';
import Invoice from './../../../container/utilityInvoice';

import { Tooltip, Modal } from 'antd';
import { getUTCToLocalDateFormat, getUTCToLocalDateTimeFormat, getExportFormat } from '../../../Utils/function';

function EletrictiyReport(props) {
    // console.log("props -------",props.search)
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    // const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";
    const searchStatus = props.searchStatus ? props.searchStatus : "";
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isInvoiceModal, setInvoiceModal] = useState(false);
    const [isInvoiceData, setInvoiceData] = useState(null);
    
    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        width: "200px"
    }, {
        name: 'Transaction ID',
        cell: row => <span className="pointer" onClick={() => handleInvoice(row)}><u>{row.transactionId}</u></span>,
        width: "180px"
    }, {
        name: 'Provider',
        selector: row => <Tooltip title={row.electricityproviders.name}>{row.provider}</Tooltip>,
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber,
        width: "150px"
    }, {
        name: 'Due On',
        selector: row => row.billDueDate ? getUTCToLocalDateFormat(row.billDueDate) : '-',
        width: "150px"
    }, {
        name: 'Name',
        selector: row => row.name,
        width: "150px"
    }, {
        name: 'Bill Date',
        cell: row => row.billDate,
        width: "150px"
    },{
        name: 'Bill Due Date',
        cell: row => row.billDueDateOn,
        width: "150px"
    },{
        name: 'Contact No.',
        selector: row => row.mobileUnitCycle ? row.mobileUnitCycle : "-",
        width: "150px"
    }, {
        name: 'Amount',
        selector: row => row.billAmount,
    }, {
        name: 'Status',
        cell: row => <>{UitilityOfflineStatus(row.status)}</>,
        width: "150px"
    },{
        name: 'Service ID',
        selector: row => row.apiId,
        width: "150px"
    },{
        name: 'Operator ID',
        selector: row => row.operatorId,
        width: "150px"
    },{
        name: 'Action',
        selector: row => <div className='icon-border border border-warning text-warning pointer' onClick={() => handleInvoice(row)} title='Invoice'><i class="fas fa-file-invoice"></i></div>,
        width: "150px"
    }]

    const handleInvoice = (data) => {
        // console.log("data --------", data)
        setInvoiceModal(true);
        setInvoiceData(data)
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = {
        page: page,
        limit: limit
    };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        if (search) {
            params.search = search ? search : "";
        }
        if(searchStatus) {
            params.searchStatus = searchStatus ? searchStatus : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ELETRICITY_BILL_PAYMENT_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (search) {
            params.search = search ? search : "";
        }
        if(searchStatus) {
            params.searchStatus = searchStatus ? searchStatus : "";
        }
        const queryString = objectToQueryString(params);
        getRequest(`${ELETRICITY_BILL_PAYMENT_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                console.log(" CSV ------------")
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date": getExportFormat(e.updatedAt),
                        "Transaction ID": e.transactionId,
                        "Provider": e.electricityproviders.name,
                        "Account Number": e.accountNumber,
                        "Due On": e.billDueDate ? getUTCToLocalDateFormat(e.billDueDate) : "",
                        "Name": e.name,
                        "Bill Date": e.billDate,
                        "Bill Due Date": e.billDueDateOn,
                        "Contact No.": e.mobileUnitCycle ? e.mobileUnitCycle : "-",
                        "Amount": e.billAmount,
                        "Status": UitilityOfflineStatus(e.status),
                        "Service ID": e.apiId,
                        "Operator ID": e.operatorId,
                    });
                });
                // await this.setState({ data: res.data.data, csvdata : csvdata });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const handleHideModal = () => {
        setInvoiceModal(false);
    }

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='electricity-report.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                    </div>
                    <div className="box-body">
                        <DataTable
                            title={''}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            {isInvoiceModal && <Modal title={<div className='col-md-12 row text-left'>
                <div className='col-md-6'>
                    <img className='text-left' src='../logo.png' alt='photos' width={"50%"} />
                </div>
                {/* <div className='col-md-6 text-right'>
                    <img className='text-right' src={'../images/bharat-billpay-logo-1@2x.png'} alt='photos' width={"40%"} />
                </div> */}
            </div>} width={800} open={isInvoiceModal} footer={false} onCancel={handleHideModal}>
                <Invoice data={isInvoiceData} category="Electrictiy" />
            </Modal>}
        </div>
    )
}

export default EletrictiyReport