import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_DMT_TRANSACTION_DETAIL_HISTORY, GET_DMT_TRANSACTION_INQUIRY, POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY, POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY, POST_DMT_TRANSACTION_UPDATE } from './../../../Utils/appConstants';

import { Select, Modal, Divider } from 'antd';
import { channelStatus, dmtDetailStatus, dmtDetailStatusInArray, dmtModeInArray } from './../../../Utils/Common';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
// import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip, Space, Tag } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from '../../../Utils/function';
import { toast } from 'react-toastify';

function DmtDetailHistory(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [searchUserId, setSearchUserId] = useState(null);
    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);

    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [searchMode, setSearchMode] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    const [isLoading, setLoading] = useState(false);
    const [isEditModal, setEditModal] = useState(false);

    const [isSelectedData, setSelectedData] = useState(null);
    const [editStatus, setEditStatus] = useState(null);
    const [editTid, setEditTid] = useState(null);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.dmttransactions.createdAt),
        width: "190px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" className='pointer' onClick={() => handleEdit(row)} title={row.dmttransactions && row.dmttransactions.client_ref_id}><u>{row.dmttransactions && row.dmttransactions.client_ref_id}</u></Tooltip>,
        width: "180px",
    }, {
        name: 'Amount',
        selector: row => row.amount + ' ' + row.dmttransactions.currency,
        width: "100px",
    }, {
        name: 'Status',
        selector: row => dmtDetailStatus(row.tx_status),
        width: "180px",
    }, {
        name: 'Inquiry',
        selector: (record, key) => (
            <><Space key={key} size="middle">
                {!record.isRefund /*&& (record.tx_status === "1" || record.tx_status === "2" || record.tx_status === "3" || record.tx_status === "5")*/ ? <Tag color={"yellow"} onClick={() => transactionInquiry(record)} className='pointer' key={0}>
                    INQUIRY
                </Tag> : "-"}
            </Space></>
        )
    }, {
        name: 'TID',
        selector: row => <Tooltip placement="bottom" title={row.tid}>{row.tid}</Tooltip>,
        width: "200px",
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.dmttransactions.channel),
    }, {
        name: 'Sub Trans ID',
        selector: row => <Tooltip placement="bottom" className='pointer' onClick={() => handleEdit(row)} title={row.transactionId}><u>{row.transactionId}</u></Tooltip>,
        width: "180px",
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.users && row.users.username}>{row.users && row.users.username}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank RRN',
        selector: row => <Tooltip placement="bottom" title={row.bank_ref_num}>{row.bank_ref_num}</Tooltip>,
        width: "160px",
    }, {
        name: 'IFSC Code',
        selector: row => row.dmttransactions.ifsccode,
        width: "120px",
    }, {
        name: 'Account Number',
        selector: row => <Tooltip placement="bottom" title={row.dmttransactions.accountNumber}>{row.dmttransactions.accountNumber}</Tooltip>,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => <Tooltip placement="bottom" title={row.baneData ? row.baneData.beneName : (row.baneData1) ? row.baneData1.beneName : ""}>{row.baneData ? row.baneData.beneName : (row.baneData1) ? row.baneData1.beneName : ""}</Tooltip>,
        width: "150px",
    }, {
        name: 'Reason',
        selector: row => <Tooltip placement="bottom" title={row.reason}>{row.reason}</Tooltip>,
        width: "180px",
    }, {
        name: 'Action',
        selector: row => <div className="pointer" onClick={() => handleEdit(row)} ><button className='btn btn-sm btn-success'><i class="fas fa-edit"></i></button></div>,
    }];

    const handleEdit = (data) => {
        setSelectedData(data);
        setEditModal(true);
        setEditStatus(data.tx_status);
        setEditTid(data.tid);
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.searchMode = searchMode ? searchMode : '';
        setLoading(true);
        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANSACTION_DETAIL_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.searchMode = searchMode ? searchMode : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANSACTION_DETAIL_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getUTCToLocalDateTimeFormat(e.dmttransactions.createdAt),
                        "Trans ID": e.dmttransactions.client_ref_id,
                        "Amount": e.amount,
                        "Status": e.txstatus_desc,
                        "TID": e.tid,
                        "Mode": channelStatus(e.dmttransactions.channel),
                        "Sub Trans ID": e.transactionId,
                        "Customer ID": e.customer_id,
                        "Partner ID": e.users && e.users.username && e.users.username,
                        "Bank RRN": e.bank_ref_num,
                        "IFSC Code": e.dmttransactions.ifsccode,
                        "Account Number": e.dmttransactions.accountNumber,
                        "Bene Name": e.baneData ? e.baneData.beneName : (e.baneData1) ? e.baneData1.beneName : "",
                        "Reason": e.reason,
                    });
                });
                setCsvData(csvdataRecord);
                csvLink.current.link.click()
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const transactionInquiry = (data) => {
        // console.log("data -------------", data.parentTransactionId)
        setLoading(true)
        const payload = {
            transactionId: data.transactionId,
        }
        let URL = "";
        if (data.transactionId.includes("SI") || data.apiName === "Instant Pay") {
            URL = POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY;
        } else if (data.transactionId.includes("SP")) {
            URL = POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY;
        } else {
            URL = GET_DMT_TRANSACTION_INQUIRY;
        }
        postRequest(`${URL}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const onChangeType = (value) => {
        // const { value } = event;
        setSearchStatus(value);
    }

    const onChangeEditStatus = (value) => {
        setEditStatus(value);
    }

    const onChangeTypeMode = (value) => {
        // const { value } = event;
        setSearchMode(value);
    }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
        setSearchUserId('');
    }

    const handleSubmit = () => {
        let payload = {
            id: isSelectedData._id,
            status : editStatus,
            tid : editTid,
        }
        // console.log("payload ---------", payload);
        postRequest(`${POST_DMT_TRANSACTION_UPDATE}`, payload, history).then((response) => {
            if (response.success === true) {
                setEditModal(false);
                toast.success(response.message);
                // getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    // useEffect(() => {
    //     if (searchAccountNumber || searchTransId || searchCustomerId || searchUserId || searchMode) {
    //         handleSearch();
    //     }
    // }, [searchAccountNumber, searchTransId, searchCustomerId, searchUserId, searchMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Money Transfer <span className='text-red'>Detail Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-1">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} value={searchAccountNumber} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} value={searchCustomerId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtModeInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Mode --" name='mode' onChange={(e) => onChangeTypeMode(e)} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtDetailStatusInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-8'></div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                                <div className='col-md-2'>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                    <CSVLink
                                                        data={getCsvData}
                                                        filename='dmtDetailList'
                                                        className='hidden'
                                                        ref={csvLink}
                                                        target='_blank'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            progressPending={isLoading}
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModal && <Modal title={"Edit Details"} width={600} open={isEditModal} onCancel={() => setEditModal(false)} footer={false}>{isSelectedData ?
                <ul>
                    <li><strong>Name</strong> : {isSelectedData.baneData1 && isSelectedData.baneData1.beneName ? isSelectedData.baneData1.beneName : (isSelectedData.baneData && isSelectedData.baneData.beneName)}</li>
                    <li><strong>A/C</strong> : {isSelectedData.dmttransactions.accountNumber}</li>
                    <li><strong>IFSC</strong> : {isSelectedData.dmttransactions.ifsccode}</li>
                    <li><strong>Amount</strong> : {isSelectedData.amount}</li>
                    <li><strong>Transaction ID</strong> : {isSelectedData.transactionId}</li>
                    <li className='d-flex'><strong>Status </strong> : {dmtDetailStatus(isSelectedData.tx_status)}</li>
                    <Divider />
                </ul>
                : ""}
                <div className="row ">
                    <div className='col-md-12 form-group'>
                        <label>Status: </label>
                        <Select options={dmtDetailStatusInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeEditStatus(e)} />
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>TID: </label>
                        <input type='text' className="form-control" maxLength={25} name='tid' value={editTid} onChange={(e) => setEditTid(e.target.value)} />
                    </div>
                    <div className="col-md-12 d-flex">
                        <div className="mr-2">
                            <button className="btn btn-success" onClick={() => handleSubmit()}>Submit</button>
                        </div>
                        <div className="mr-2">
                            <button className="btn btn-default" onClick={() => setEditModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default DmtDetailHistory