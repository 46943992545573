import React, { useEffect, useState } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_MY_LEDGER_REPORTS } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { INRFormat } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import Moment from 'react-moment';
// import moment from 'moment';

import { DatePicker, Tooltip} from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';

function Ledger(props) {

    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    // const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "180px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Status',
        selector: row => '-',
    }, {
        name: 'Ref ID',
        selector: row => row.toUserData && row.toUserData.username,
        width: "150px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Service Name',
        selector: row => <Tooltip placement="bottom" title={row.serviceName}>{row.serviceName}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Product',
        selector: row => <Tooltip placement="bottom" title={row.product}>{row.product}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    // }, {
    //     name: 'Name',
    //     selector: row => <Tooltip placement="bottom" title={row.toUserData && row.toUserData.name}>{row.toUserData && row.toUserData.name}</Tooltip>,
    //     width: "200px",
    //     headerStyle: (selector, id) => {
    //       return { textAlign: "center" };   // removed partial line here
    //     },
    }, {
        name: 'Opening Balance',
        selector: row => row.openingBalance ? INRFormat(row.openingBalance) : "-",
        width: "150px",
    }, {
        name: 'Debit',
        selector: row => (!row.debit) ? "-" : INRFormat(row.debit),
        width: "130px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Credit',
        selector: row => (!row.credit) ? "-" : INRFormat(row.credit),
        width: "130px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Ser. Charge',
        selector: row => row.serviceCharge ? INRFormat(row.serviceCharge) : "-",
    }, {
        name: 'Commission',
        selector: row => row.commission ? INRFormat(row.commission) : "-",
    }, {
        name: 'TDS',
        selector: row => row.tds ? INRFormat(row.tds) : "-",
    }, {
        name: 'Closing Balance',
        selector: row => INRFormat(row.closingBalance),
        width: "150px",
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Description',
        selector: row => row.description ? <Tooltip placement="bottom" title={row.description && row.description}>{row.description && row.description}</Tooltip> : "-",
        width: "250px",
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = ( data ) => {
        if(data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if(props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_MY_LEDGER_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const getCSVDataList = () => {
    //     let params = {}
    //     params.exportsCsv = true;
    //     if (startDate && endDate) {
    //         params.startDate = startDate ? startDate : "";
    //         params.endDate = endDate ? endDate : "";
    //     }

    //     const queryString = objectToQueryString(params);
    //     getRequest(`${GET_MY_LEDGER_REPORTS}?${queryString}`, history).then((response) => {
    //         if (response.success === true) {
    //             let csvdataRecord = [];
    //             let csvdata = response.data;
    //             csvdata.map((e) => {
    //                 return csvdataRecord.push({ "Date": moment(e.updatedAt).format("YYYY-MM-DD HH:mm A"), "TXT Id": e.transactionId, "FTR Reference Number": e.FTRReferenceNumber, "Name": e.fromUserDetails.name, "User ID": e.fromUserDetails.username, "Debit": e.debit, "Credit": e.credit, "Closing Balance": e.closingBalance, "Payment Mode": e.paymentMode, "Remark": e.remark });
    //             });
    //             setCsvData(csvdataRecord);
    //             csvLink.current.link.click()
    //         } else {
    //             setCsvData([]);
    //         }
    //     }).catch(function (error) {
    //         console.log(error)
    //         // setLoading(false);
    //     })
    // }

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };
    
    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        handleSearch();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="">
                <div className="">
                    <div className="">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-6 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-sm-4 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-lg-2'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ledger