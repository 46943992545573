import React, { useEffect, useState } from 'react';
// import CsvDownloader from 'react-csv-downloader';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { WATER_BILL_PAYMENT_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { UitilityOfflineStatus } from './../../../Utils/Common';
// import moment from 'moment';
// import Moment from 'react-moment';
import { Tooltip } from 'antd';
import { getUTCToLocalDateFormat, getUTCToLocalDateTimeFormat } from '../../../Utils/function';

function WaterPaymentReport(props) {
    // console.log("props -------",props.search)
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    // const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";
    const searchStatus = props.searchStatus ? props.searchStatus : "";
    
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    
    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        width: "200px"
    }, {
        name: 'Transaction ID',
        cell: row => row.transactionId,
        width: "180px"
    },{
        name: 'Provider',
        selector: row => <Tooltip title={row.waterproviders.name}>{row.provider}</Tooltip>,
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber,
        width: "190px"
    }, {
        name: 'Due On',
        selector: row => row.billDueDate ? getUTCToLocalDateFormat(row.billDueDate) : '-',
        width: "150px"
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
        width: "120px"
    }, {
        name: 'Name',
        selector: row => row.name,
        width: "150px"
    }, {
        name: 'Bill Date',
        cell: row => row.billDate,
        width: "120px"
    }, {
        name: 'Bill Due Date',
        cell: row => row.billDueDateOn,
        width: "120px"
    }, {
        name: 'Amount',
        selector: row => row.billAmount,
        width: "100px"
    }, {
        name: 'Status',
        cell: row => <>{UitilityOfflineStatus(row.status)}</>,
    }, {
        name: 'Service ID',
        selector: row => row.apiId,
        width: "150px"
    }, {
        name: 'Operator ID',
        selector: row => row.operatorId,
        width: "150px"
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (search) {
            params.search = search ? search : "";
        }
        if(searchStatus) {
            params.searchStatus = searchStatus ? searchStatus : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${WATER_BILL_PAYMENT_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const getCSVDataList = () => {
    //     let params = {}
    //     params.exportsCsv = true;
    //     if (startDate && endDate) {
    //         params.startDate = startDate ? startDate : "";
    //         params.endDate = endDate ? endDate : "";
    //     }

    //     if (search) {
    //         params.search = search ? search : "";
    //     }
    //     if(searchStatus) {
    //         params.searchStatus = searchStatus ? searchStatus : "";
    //     }
    //     const queryString = objectToQueryString(params);
    //     getRequest(`${WATER_BILL_PAYMENT_REPORT}?${queryString}`, history).then((response) => {
    //         if (response.success === true) {
    //             let csvdataRecord = [];
    //             let csvdata = response.data;
    //             csvdata.map((e) => {
    //                 return csvdataRecord.push({ "Provider": e.provider, "Customer Id": e.customerId, "States": e.states, "Amount": e.amount, "Status": e.status,  "Date": e.createdAt });
    //             });
    //             setCsvData(csvdataRecord);
    //             csvLink.current.link.click()
    //         } else {
    //             setCsvData([]);
    //         }
    //     }).catch(function (error) {
    //         console.log(error)
    //         // setLoading(false);
    //     })
    // }

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    {/* <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='dth-recharge-report.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button>
                    </div> */}
                    <div className="box-body">
                        <DataTable
                            title={''}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaterPaymentReport